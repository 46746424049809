export default {
  lmh: (value) => {
    if (value < 25) {
      return 'Low'
    } else if (value >= 25 && value < 75) {
      return 'Medium'
    } else if (value >= 75) {
      return 'High'
    } else {
      return 'Not available'
    }
  },
  nlmh: (value) => {
    if (value < 25) {
      return 'No'
    } else if (value >= 25 && value < 50) {
      return 'Low'
    } else if (value >= 50 && value < 75) {
      return 'Medium'
    } else if (value >= 75) {
      return 'High'
    } else {
      return 'Not available'
    }
  },
  nmh: (value) => {
    if (value < 25) {
      return 'No'
    } else if (value >= 25 && value < 75) {
      return 'Medium'
    } else if (value >= 75) {
      return 'High'
    } else {
      return 'Not available'
    }
  }
}
